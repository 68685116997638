const helperObj = {
  setCookie: function (c_name, data, path, exdays, exMinute) {
    if (exdays === void 0) {
      exdays = 365;
    }
    if (exMinute === void 0) {
      exMinute = 0;
    }
    var value = data;
    if (typeof data === "object") {
      value = JSON.stringify(data);
    }
    path = path === undefined ? "" : ";path=" + path;
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    exdate.setMinutes(exdate.getMinutes() + exMinute);
    var c_value =
      decodeURI(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value + path;
  },
  getCookie: function (c_name) {
    var cookies = document.cookie.split(";").filter(function (item, value) {
      return item.trim().split("=")[0] == c_name;
    });
    var cookieValues = [];
    if (cookies && cookies.length) {
      cookieValues = cookies.map(function (item, index) {
        var cookieValue = item.trim().split("=")[1];
        if (
          cookieValue.indexOf("{", 0) == 0 &&
          cookieValue.lastIndexOf("}") == cookieValue.length - 1
        ) {
          cookieValue = JSON.parse(cookieValue);
        }
        return cookieValue;
      });
    }
    return cookieValues.length ? cookieValues[0] : "";
  },
};
window.helperObj = helperObj;
const en = {
  Home: "Home",
  Boost: "Boost",
  Rejected: "Rejected",
  Completed: "Completed",
  Pending: "Pending",
  Profile: "Profile",
  Record: "Record",
  Remark: "Remark",
  Starting: "Starting",
  Transactions: "Transactions",
  WithdrawalAmount: "Withdrawal Amount",
  WithdrawalHistory: "Withdrawal History",
  TopWithdrawal: "Top Withdrawal",
  Withdrawal: "Withdrawal",
  Invite: "Invite",
  LuckyDraw: "Lucky Draw",
  BindAccount: "Bind Account",
  BankName: "Bank Name",
  BankAccountName: "Bank Account Name",
  BankAccountNo: "Bank Account No",
  Recharge: "Recharge",
  ProductName: "Product Name",
  ProductNumber: "Product Number",
  OrderHistory: "Order History",
  All: "All",
  BindWallet: "Bind Wallet",
  Logout: "Logout",
  MandatoryWalletAddress: "*Wallet Address",
  Exchange: "Exchange",
  Commission: "Commission",
  CurrentBalance: "Current Balance",
  ContactUs: "Contact Us",
  LoginNameMobileNo: "Username / Mobile No",
  Password: "Password",
  ConfirmPassword: "Confirm Password",
  WithdrawalPassword: "Withdrawal Password",
  ChangePassword: "Change Password",
  ForgotPass: "Forgot Password?",
  SignIn: "Sign In",
  Register: "Register",
  Username: "Username",
  Login: "Login",
  RegisterAgreement: "Register Agreement",
  MobileNo: "Mobile No",
  InvitationCode: "Invitation Code",
  CreditScore: "Credit Score",
  BackToLogin: "Back To Login",
  MembershipLevel: "Membership Level",
  TodaysCommission: "Today's commission",
  TotalAmount: "Total Amount",
  LatestEvent: "Latest Event",
  AboutUs: "About Us",
  TNC: "T&C",
  TeamReport: "Team Report",
  Certificate: "Certificate",
  OnlineUsers: "Online Users",
  FromLastMonth: "From Last Month",
  OptimizeDemand: "Optimize Demand",
  OrderCompletion: "Order Completion",
  OrderQuantity: "Order Quantity",
  Confirm: "Confirm",
  Cancel: "Cancel",
  Submit: "Submit",
  Profit: "Profit",
  Value: "Value",
  StartNow: "Start Now",
  AccountBalance: "Account Balance",
  FreezeAmount: "Processing Amount",
  Frozen: "Processing",
  MessageList: "Message List",
  OrderSubmission: "Order Submission",
  CreateTime: "Create Time",
  OrderNumber: "Order Number",
  OrderDetails: "Order Details",
  MyReferralCode: "My Referral Code",
  CopyReferralCode: "Copy Referral Code",
  msg_login_success: "Login Success",
  msg_logout_confirm: "Are you sure you want to log out account?",
  msg_workingtime: "Working time",
  msg_depositwithdrawal:
    "For deposits and withdrawals, please contact our Customer Service",
  msg_clicktocontactus: "Click To Contact Customer Service",
  msg_accountnotexist: "Invalid account or password",
  msg_invalidpassword: "Invalid password",
  msg_grab_order_period:
    "This operation can only be performed during the specified time [START]:00 - [END]:00",
  msg_withdrawalamount_range: "Withdrawal limit is [MINAMT] - [MAXAMT]",
  msg_grab_order_disabled:
    "This operation disabled. Please contact customer service.",
  msg_account_disabled: "The account has been disabled",
  msg_account_frozen: "The account transaction function has been frozen",
  msg_account_gap_amount:
    "Your account balance is not enough, there is a gap of [REPLACEVALUE1]. Please contact customer service.",
  msg_order_processing_success: "Order processing completed",
  msg_progress_title:
    "Extracting at full capacity, Estimate need<span style='color: red'> &nbsp;3s&nbsp;</span>Please don't leave the interface!",
  msg_progress_note1: "Synchronizing latest information...",
  msg_progress_note2: "Checking product information...",
  msg_progress_note3: "Matching product orders...",
  msg_progress_note4: "Processing the best product...",
  msg_receive_withdrawal_transfer:
    "You will receive your withdrawal within half an hour",
  msg_insufficient_creditbalance: "Insufficient credit",
  msg_insufficient_creditscore: "Insufficient credit score",
  msg_invalid_password: "Invalid Password",
  msg_input_withdrawalpassword: "Please input withdrawal password",
  msg_please_input_userid: "Please input your username",
  msg_please_input_pass: "Please input login password",
  msg_please_input_mobileno: "Please input your mobile no",
  msg_pass_min_length: "Password length must be at least 8",
  msg_pass_format:
    "Password must contain letters and numbers only with 8 to 50 length",
  msg_pass_confirm: "Please input login password again",
  msg_withpass_min_length: "Withdrawal password length must be at least 8",
  msg_withpass_format:
    "Withdrawal password must contain letters and numbers only with 8 to 50 length",
  msg_please_input_invitecode: "Please input the invitation code",
  msg_balance_insufficient:
    "Unable to start trading due to balance is lower than minimum activation value. Please contact the customer service for further assistance",
  msg_recharge: "Please contact the customer service for further assistance",
  msg_bank_pass_notice:
    "Dear user, Please do not enter your own bank account password in this box. Our staff will not demand you to provide your bank account password.",
  msg_mandatory_bankname: "Please enter bank name",
  msg_mandatory_bankaccname: "Please enter bank account name",
  msg_mandatory_bankaccno: "Please enter bank account no",
  msg_bank_pass_notice2:
    "Notes: If user need to reset wallet address, please contact our customer service.",
  msg_luckydraw_insufficientdrawcount: "Insufficient draw count",
  msg_username_exist: "Duplicate username",
  msg_invalid_invitationcode: "Invalid invitation code",
  msg_inactive_invitationcode: "Invitation code inactive",
  msg_invitation_notallowed: "Your referrer does not have permission to invite",
  msg_mandatory_ordercompletebeforerequest:
    "Complete your orders before submit new request",
};
window.en = en;
const kr = {
  Home: "홈",
  Boost: "부스트",
  Rejected: "거부됨",
  Completed: "완료됨",
  Pending: "대기 중",
  Remark: "비고",
  Profile: "윤곽",
  Record: "기록",
  Remark: "주목",
  Starting: "시작",
  Transactions: "업무",
  WithdrawalAmount: "출금 금액",
  WithdrawalHistory: "출금 내역",
  TopWithdrawal: "최고 출금",
  Withdrawal: "철수",
  Invite: "초대하다",
  LuckyDraw: "행운의 추첨",
  BindAccount: "계정 연동",
  BankName: "은행명",
  BankAccountName: "은행 계좌명",
  BankAccountNo: "은행 계좌번호",
  Recharge: "충전",
  ProductName: "상품명",
  ProductNumber: "상품 번호",
  OrderHistory: "주문 내역",
  All: "전체",
  BindWallet: "지갑 연동",
  Logout: "로그아웃",
  MandatoryWalletAddress: "*지갑 주소",
  Exchange: "환전",
  Commission: "수수료",
  CurrentBalance: "현재 잔액",
  ContactUs: "문의하기",
  LoginNameMobileNo: "사용자명 / 휴대폰 번호",
  Password: "비밀번호",
  ConfirmPassword: "비밀번호 확인",
  WithdrawalPassword: "출금 비밀번호",
  ChangePassword: "비밀번호 변경",
  ForgotPass: "비밀번호를 잊으셨나요?",
  SignIn: "로그인",
  Register: "가입하기",
  Username: "사용자명",
  Login: "로그인",
  RegisterAgreement: "가입 약관",
  MobileNo: "휴대폰 번호",
  InvitationCode: "초대 코드",
  CreditScore: "신용 점수",
  BackToLogin: "로그인 화면으로 돌아가기",
  MembershipLevel: "회원 등급",
  TodaysCommission: "오늘의 수수료",
  TotalAmount: "총 금액",
  LatestEvent: "최신 이벤트",
  AboutUs: "회사 소개",
  TNC: "이용 약관",
  TeamReport: "팀 보고서",
  Certificate: "증명서",
  OnlineUsers: "온라인 사용자",
  FromLastMonth: "지난달부터",
  OptimizeDemand: "수요 최적화",
  OrderCompletion: "주문 완료",
  OrderQuantity: "주문 수량",
  Confirm: "확인",
  Cancel: "취소",
  Submit: "제출",
  Profit: "이익",
  Value: "가치",
  StartNow: "지금 시작하기",
  AccountBalance: "계좌 잔액",
  FreezeAmount: "처리 중 금액",
  Frozen: "진행 중",
  MessageList: "메시지 목록",
  OrderSubmission: "주문 제출",
  CreateTime: "생성 시간",
  OrderNumber: "주문 번호",
  OrderDetails: "주문 세부 사항",
  MyReferralCode: "내 추천 코드",
  CopyReferralCode: "추천 코드 복사",
  msg_login_success: "로그인 성공",
  msg_logout_confirm: "계정을 로그아웃하시겠습니까?",
  msg_workingtime: "근무 시간",
  msg_depositwithdrawal: "입출금을 위해 고객센터에 문의하세요",
  msg_clicktocontactus: "고객센터에 문의하려면 클릭하세요",
  msg_accountnotexist: "계정 또는 비밀번호가 올바르지 않습니다",
  msg_invalidpassword: "잘못된 비밀번호",
  msg_grab_order_period:
    "이 작업은 지정된 시간 [START]:00 - [END]:00에만 수행할 수 있습니다",
  msg_withdrawalamount_range: "출금 한도는 [MINAMT] - [MAXAMT]입니다",
  msg_grab_order_disabled:
    "이 작업이 비활성화되었습니다. 고객센터에 문의하세요.",
  msg_account_disabled: "계정이 비활성화되었습니다",
  msg_account_frozen: "계정 거래 기능이 동결되었습니다",
  msg_account_gap_amount:
    "계좌 잔액이 부족합니다. 부족한 금액: [REPLACEVALUE1]. 고객센터에 문의하세요.",
  msg_order_processing_success: "주문 처리가 완료되었습니다",
  msg_progress_title:
    "최대 용량으로 추출 중입니다. 약<span style='color: red'> &nbsp;3초&nbsp;</span> 필요합니다. 인터페이스를 떠나지 마십시오!",
  msg_progress_note1: "최신 정보 동기화 중...",
  msg_progress_note2: "상품 정보 확인 중...",
  msg_progress_note3: "상품 주문 매칭 중...",
  msg_progress_note4: "최상의 상품 처리 중...",
  msg_receive_withdrawal_transfer: "출금은 30분 이내에 처리됩니다",
  msg_insufficient_creditbalance: "잔액 부족",
  msg_insufficient_creditscore: "신용 점수가 부족합니다",
  msg_invalid_password: "잘못된 비밀번호",
  msg_input_withdrawalpassword: "출금 비밀번호를 입력하세요",
  msg_please_input_userid: "사용자 이름을 입력하세요",
  msg_please_input_pass: "로그인 비밀번호를 입력하세요",
  msg_please_input_mobileno: "휴대폰 번호를 입력하세요",
  msg_pass_min_length: "비밀번호는 최소 8자 이상이어야 합니다",
  msg_pass_format:
    "비밀번호는 영문자와 숫자로만 구성되어야 하며 길이는 8~50자여야 합니다",
  msg_pass_confirm: "로그인 비밀번호를 다시 입력하세요",
  msg_withpass_min_length: "출금 비밀번호는 최소 8자 이상이어야 합니다",
  msg_withpass_format:
    "출금 비밀번호는 영문자와 숫자로만 구성되어야 하며 길이는 8~50자여야 합니다",
  msg_please_input_invitecode: "초대 코드를 입력하세요",
  msg_balance_insufficient:
    "최소 활성화 값보다 잔액이 부족하여 거래를 시작할 수 없습니다. 고객센터에 문의하세요",
  msg_recharge: "고객센터에 문의하세요",
  msg_bank_pass_notice:
    "사용자님, 이란에 본인 은행 계좌 비밀번호를 입력하지 마세요. 저희 직원은 계좌 비밀번호를 요구하지 않습니다.",
  msg_mandatory_bankname: "은행 이름을 입력하세요",
  msg_mandatory_bankaccname: "계좌명을 입력하세요",
  msg_mandatory_bankaccno: "계좌번호를 입력하세요",
  msg_bank_pass_notice2:
    "참고: 사용자가 지갑 주소를 재설정하려면 고객센터에 문의하세요.",
  msg_luckydraw_insufficientdrawcount: "추첨 횟수가 부족합니다",
  msg_username_exist: "중복된 사용자 이름입니다",
  msg_invalid_invitationcode: "잘못된 초대 코드입니다",
  msg_inactive_invitationcode: "초대 코드가 비활성화되었습니다",
  msg_invitation_notallowed: "추천인은 초대 권한이 없습니다",
  msg_mandatory_ordercompletebeforerequest:
    "새 요청을 제출하기 전에 주문을 완료하세요",
};
window.kr = kr;
var es = {
  BankName: "Nombre del banco",
  BankAccountName: "Nombre de la cuenta bancaria",
  BankAccountNo: "Número de cuenta bancaria",
  BindAccount: "Vincular cuenta",
  Transactions: "Actas",
  Home: "Inicio",
  Boost: "Impulsar",
  Rejected: "Rechazado",
  Completed: "Completado",
  Pending: "Pendiente",
  Remark: "Observación",
  WithdrawalAmount: "Monto de Retiro",
  WithdrawalHistory: "Historial de Retiros",
  TopWithdrawal: "Retiro Superior",
  Recharge: "Recarga",
  Withdrawal: "Retiro",
  Transaction: "Transacción",
  Invite: "Invitar",
  HOME: "INICIO",
  Starting: "Comenzando",
  Record: "Registro",
  Profile: "Perfil",
  ProductName: "Nombre del Producto",
  ProductNumber: "Número de Producto",
  OrderHistory: "Historial de Pedidos",
  All: "Todo",
  BindWallet: "Vincular Billetera",
  Logout: "Cerrar Sesión",
  MandatoryWalletAddress: "*Dirección de Billetera Obligatoria",
  Exchange: "Nombre del Intercambio",
  Commission: "Comisión",
  CurrentBalance: "Saldo Actual",
  ContactUs: "Contáctanos",
  LoginNameMobileNo: "Nombre de Usuario / Número de Teléfono Móvil",
  Password: "Contraseña",
  ConfirmPassword: "Confirmar Contraseña",
  WithdrawalPassword: "Contraseña de Retiro",
  ChangePassword: "Cambiar Contraseña",
  ForgotPass: "¿Olvidaste tu contraseña?",
  SignIn: "Iniciar Sesión",
  Register: "Registrar",
  Username: "Nombre de Usuario",
  Login: "Iniciar Sesión",
  RegisterAgreement: "Acuerdo de Registro",
  MobileNo: "Número de Teléfono",
  InvitationCode: "Código de Invitación",
  CreditScore: "Puntuación de Crédito",
  BackToLogin: "Volver a Iniciar Sesión",
  MembershipLevel: "Nivel de Membresía",
  TodaysCommission: "Comisión de Hoy",
  TotalAmount: "Cantidad Total",
  LatestEvent: "Último Evento",
  AboutUs: "Acerca de Nosotros",
  TNC: "Términos y Condiciones",
  TeamReport: "Informe de Equipo",
  Certificate: "Certificado",
  OnlineUsers: "Usuarios en Línea",
  FromLastMonth: "Desde el Mes Pasado",
  OrderCompletion: "Finalización de Pedido",
  OptimizeDemand: "Optimizar Demanda",
  OrderQuantity: "Cantidad de Pedido",
  Confirm: "Confirmar",
  Cancel: "Cancelar",
  Submit: "Enviar",
  Profit: "Ganancia",
  Value: "Valor",
  StartNow: "Empieza Ahora",
  AccountBalance: "Saldo de la Cuenta",
  FreezeAmount: "Monto Congelado",
  Frozen: "Tratamiento",
  MessageList: "Lista de Mensajes",
  OrderSubmission: "Envío de Pedido",
  CreateTime: "Hora de Creación",
  OrderNumber: "Número de Pedido",
  OrderDetails: "Detalles del Pedido",
  MyReferralCode: "Mi Código de Referido",
  CopyReferralCode: "Copiar Código de Referido",
  msg_login_success: "Inicio de sesión exitoso",
  msg_logout_confirm: "¿Estás seguro de que deseas cerrar sesión?",
  msg_workingtime: "Horario de trabajo",
  msg_depositwithdrawal:
    "Para consultas sobre depósitos y retiros, comuníquese con nuestro servicio al cliente.",
  msg_clicktocontactus: "Haz clic para contactar con el servicio al cliente.",
  msg_accountnotexist: "Cuenta o contraseña incorrecta.",
  msg_invalidpassword: "Contraseña inválida.",
  msg_grab_order_period:
    "Esta operación solo se puede realizar entre las [START]:00 - [END]:00.",
  msg_withdrawalamount_range:
    "El rango de retiro permitido es de [MINAMT] - [MAXAMT].",
  msg_grab_order_disabled:
    "Esta operación está deshabilitada. Contacte al servicio al cliente.",
  msg_account_disabled: "La cuenta ha sido deshabilitada.",
  msg_account_frozen: "La función de operaciones de la cuenta está congelada.",
  msg_account_gap_amount:
    "Su saldo es insuficiente, falta [REPLACEVALUE1]. Contacte al servicio al cliente.",
  msg_order_processing_success: "El pedido se procesó con éxito.",
  msg_progress_title:
    "Trabajando a toda capacidad, se estima que tomará<span style='color: red'> &nbsp;3s&nbsp;</span>. ¡Por favor, no abandones la interfaz!",
  msg_progress_note1: "Sincronizando la información más reciente...",
  msg_progress_note2: "Verificando información del producto...",
  msg_progress_note3: "Emparejando pedidos de productos...",
  msg_progress_note4: "Procesando el mejor producto...",
  msg_receive_withdrawal_transfer: "Recibirás los fondos en media hora.",
  msg_insufficient_creditbalance: "Saldo de crédito insuficiente.",
  msg_insufficient_creditscore: "Puntuación de crédito insuficiente.",
  msg_invalid_password: "Contraseña inválida.",
  msg_input_withdrawalpassword: "Por favor, ingrese la contraseña de retiro.",
  msg_please_input_userid: "Por favor, ingrese su nombre de usuario.",
  msg_please_input_pass: "Por favor, ingrese su contraseña.",
  msg_please_input_mobileno: "Por favor, ingrese su número de teléfono móvil.",
  msg_pass_min_length: "La contraseña debe tener al menos 6 caracteres.",
  msg_pass_format:
    "La contraseña debe contener solo letras y números, de 6 a 50 caracteres.",
  msg_pass_confirm: "Por favor, confirme su contraseña.",
  msg_withpass_min_length:
    "La contraseña de retiro debe tener al menos 6 caracteres.",
  msg_withpass_format:
    "La contraseña de retiro debe contener solo letras y números, de 6 a 50 caracteres.",
  msg_please_input_invitecode: "Por favor, ingrese el código de invitación.",
  msg_balance_insufficient:
    "No se puede comenzar el comercio debido a saldo insuficiente. Comuníquese con el servicio al cliente para obtener ayuda.",
  msg_recharge:
    "Por favor, comuníquese con el servicio al cliente para obtener ayuda.",
  msg_bank_pass_notice:
    "Estimado usuario, por favor, no ingrese su contraseña bancaria aquí. Nuestro personal nunca le pedirá la contraseña de su cuenta bancaria.",
  msg_mandatory_bankname: "Por favor, ingrese el nombre del banco.",
  msg_mandatory_bankaccname:
    "Por favor, ingrese el nombre de la cuenta bancaria.",
  msg_mandatory_bankaccno: "Por favor, ingrese el número de cuenta bancaria.",
  msg_bank_pass_notice2:
    "Nota: Si necesita restablecer la dirección de la billetera, comuníquese con el servicio al cliente.",
  msg_username_exist: "El nombre de usuario ya existe.",
  msg_invalid_invitationcode: "Código de invitación inválido.",
  msg_inactive_invitationcode: "El código de invitación no está activo.",
  msg_invitation_notallowed: "Tu referente no tiene permiso para invitar.",
  msg_mandatory_ordercompletebeforerequest:
    "Complete los pedidos antes de enviar una nueva solicitud.",
};
window.es = es;
var sa = {
  Rejected: "مرفوض",
  Completed: "مكتمل",
  Pending: "قيد الانتظار",
  Remark: "ملاحظة",
  WithdrawalAmount: "مبلغ السحب",
  WithdrawalHistory: "تاريخ الانسحاب",
  Submit: "يُقدِّم",
  BindWallet: "ربط المحفظة",
  MandatoryWalletAddress: "عنوان المحفظة*",
  Exchange: "تبادل",
  Commission: "عمولة",
  Notice: "يلاحظ",
  History: "تاريخ",
  OrderHistory: "تاريخ",
  All: "الجميع",
  Confirm: "يتأكد",
  Cancel: "يلغي",
  Home: "بيت",
  Starting: "البدء",
  Record: "سِجِلّ",
  Profile: "حساب تعريفي",
  Recharge: "إعادة الشحن",
  Deposit: "إيداع",
  Withdrawal: "ينسحب",
  Invite: "يدعو",
  FAQ: "الأسئلة المتداولة",
  Transactions: "عملية",
  Logout: "تسجيل الخروج",
  CurrentBalance: "الرصيد الحالي",
  ContactUs: "اتصل بنا",
  LoginNameMobileNo: "اسم المستخدم / رقم الهاتف المحمول",
  Password: "كلمة المرور",
  ConfirmPassword: "تأكيد كلمة المرور",
  WithdrawalPassword: "كلمة مرور السحب",
  ChangePassword: "تغيير كلمة المرور",
  ForgotPass: "هل نسيت كلمة المرور؟",
  SignIn: "تسجيل الدخول",
  Register: "التسجيل",
  Username: "اسم المستخدم",
  Login: "تسجيل الدخول",
  RegisterAgreement: "اتفاقية التسجيل",
  MobileNo: "رقم الهاتف المحمول",
  InvitationCode: "رمز الدعوة",
  CreditScore: "درجة الائتمان",
  BackToLogin: "العودة إلى تسجيل الدخول",
  MembershipLevel: "مستوى العضوية",
  TodaysCommission: "عمولة اليوم",
  TotalAmount: "المبلغ الإجمالي",
  LatestEvent: "آخر الأحداث",
  AboutUs: "نبذة عنا",
  TNC: "الشروط والأحكام",
  TeamReport: "تقرير الفريق",
  BindWallet: "ربط المحفظة",
  Certificate: "الشهادة",
  OnlineUsers: "المستخدمون عبر الإنترنت",
  FromLastMonth: "من الشهر الماضي",
  OptimizeDemand: "تحسين الطلب",
  OrderCompletion: "إكمال الطلب",
  OrderQuantity: "طلب الكمية",
  StartNow: "ابدأ الآن",
  AccountBalance: "رصيد الحساب",
  FreezeAmount: "مقدار پردازش",
  Frozen: "پردازش",
  MessageList: "قائمة الرسائل",
  OrderSubmission: "تقديم الطلب",
  CreateTime: "إنشاء الوقت",
  OrderNumber: "رقم الطلب",
  msg_login_success: "نجاح تسجيل الدخول",
  msg_logout_confirm: "هل أنت متأكد من رغبتك في تسجيل الخروج من الحساب؟",
  msg_workingtime: "وقت العمل",
  msg_depositwithdrawal:
    "بالنسبة لعمليات الإيداع والسحب، يُرجى الاتصال بخدمة العملاء",
  msg_clicktocontactus: "انقر للاتصال بخدمة العملاء",
  msg_accountnotexist: "حساب أو كلمة مرور غير صالحة",
  msg_invalidpassword: "كلمة مرور غير صالحة",
  msg_grab_order_period:
    "يمكن إجراء هذه العملية فقط خلال الوقت المحدد [START]:00 - [END]:00",
  msg_grab_order_disabled: "تم تعطيل هذه العملية. يرجى الاتصال بخدمة العملاء.",
  msg_account_disabled: "تم تعطيل الحساب",
  msg_account_frozen: "تم تجميد وظيفة معاملات الحساب",
  msg_account_gap_amount:
    "رصيد حسابك لا يكفي، فهناك فجوة في [REPLACEVALUE1]. يرجى الاتصال بخدمة العملاء.",
  msg_order_processing_success: "اكتملت معالجة الطلبات",
  msg_progress_note1: "مزامنة أحدث المعلومات...",
  msg_progress_note2: "التحقق من معلومات المنتج...",
  msg_progress_note3: "مطابقة طلبات المنتجات...",
  msg_progress_note4: "معالجة أفضل منتج...",
  msg_receive_withdrawal_transfer: "ستتلقى السحب الخاص بك في غضون نصف ساعة",
  msg_insufficient_creditscore: "درجة ائتمان غير كافية",
  msg_invalid_password: "كلمة مرور غير صالحة",
  msg_input_withdrawalpassword: "يرجى إدخال كلمة مرور السحب",
  msg_please_input_userid: "الرجاء إدخال اسم المستخدم الخاص بك",
  msg_please_input_pass: "الرجاء إدخال كلمة مرور تسجيل الدخول",
  msg_please_input_mobileno: "يرجى إدخال رقم هاتفك المحمول",
  msg_pass_min_length: "يجب ألا يقل طول كلمة المرور عن 8",
  msg_pass_format:
    "يجب أن تحتوي كلمة المرور على حروف وأرقام فقط بطول 8 إلى 12 حرفًا",
  msg_pass_confirm: "الرجاء إدخال كلمة مرور تسجيل الدخول مرة أخرى",
  msg_withpass_min_length: "يجب أن يكون طول كلمة مرور السحب 8 على الأقل",
  msg_withpass_format:
    "يجب أن تحتوي كلمة مرور السحب على حروف وأرقام فقط بطول 8 إلى 12",
  msg_please_input_invitecode: "يرجى إدخال رمز الدعوة",
  msg_balance_insufficient:
    "عدم امکان شروع معامله به دلیل موجودی کمتر از حداقل مقدار فعال سازی، لطفاً برای راهنمایی بیشتر با خدمات مشتری تماس بگیرید",
  msg_recharge: "لطفا برای راهنمایی بیشتر با خدمات مشتری تماس بگیرید",
  msg_bank_pass_notice:
    "کاربر گرامی لطفا رمز حساب بانکی خود را در این کادر وارد نکنید. کارکنان ما از شما تقاضای ارائه رمز حساب بانکی خود را نمی کنند.",
  msg_mandatory_bankname: "لطفا نام بانک را وارد کنید",
  msg_mandatory_bankaccname: "لطفا نام حساب بانکی را وارد کنید",
  msg_mandatory_bankaccno: "لطفا شماره حساب بانکی را وارد کنید",
  msg_bank_pass_notice2:
    "نکات: اگر کاربر نیاز به تنظیم مجدد آدرس کیف پول دارد، لطفاً با خدمات مشتری ما تماس بگیرید",
};
window.sa = sa;
var ir = {
  Rejected: "رد شد",
  Completed: "تکمیل شد",
  Pending: "در انتظار",
  Remark: "تذکر دهید",
  WithdrawalAmount: "مبلغ برداشت",
  WithdrawalHistory: "تاریخچه برداشت",
  Submit: "ارسال کنید",
  BindWallet: "کیف پول را ببندید",
  MandatoryWalletAddress: "*آدرس کیف پول",
  Exchange: "مبادله",
  Commission: "کمیسیون",
  Notice: "توجه کنید",
  History: "تاریخچه",
  OrderHistory: "تاریخچه",
  All: "همه",
  Confirm: "تایید کنید",
  Cancel: "لغو کنید",
  Home: "خانه",
  Starting: "شروع کردن",
  Record: "ثبت کنید",
  Profile: "نمایه",
  Recharge: "شارژ مجدد",
  Deposit: "سپرده گذاری",
  Withdrawal: "برداشتن",
  Invite: "دعوت کنید",
  FAQ: "دعوت کنید",
  Transactions: "معامله",
  Logout: "خروج",
  CurrentBalance: "موجودی فعلی",
  ContactUs: "تماس با ما",
  LoginNameMobileNo: "نام کاربری / شماره موبایل",
  Password: "رمز عبور",
  ConfirmPassword: "رمز عبور را تایید کنید",
  WithdrawalPassword: "رمز برداشت",
  ChangePassword: "رمز عبور را تغییر دهید",
  ForgotPass: "رمز عبور را فراموش کرده اید؟",
  SignIn: "وارد شوید",
  Register: "ثبت نام کنید",
  Username: "نام کاربری",
  Login: "ورود به سیستم",
  RegisterAgreement: "قرارداد ثبت نام",
  MobileNo: "شماره موبایل",
  InvitationCode: "کد دعوت",
  CreditScore: "امتیاز اعتباری",
  BackToLogin: "بازگشت به ورود",
  MembershipLevel: "سطح عضویت",
  TodaysCommission: "کمیسیون امروز",
  TotalAmount: "مقدار کل",
  LatestEvent: "آخرین رویداد",
  AboutUs: "درباره ما",
  TNC: "شرایط و ضوابط",
  TeamReport: "گزارش تیم",
  BindWallet: "کیف پول را ببندید",
  Certificate: "گواهی",
  OnlineUsers: "کاربران آنلاین",
  FromLastMonth: "از ماه گذشته",
  OptimizeDemand: "بهینه سازی تقاضا",
  OrderCompletion: "تکمیل سفارش",
  OrderQuantity: "مقدار سفارش",
  StartNow: "اکنون شروع کنید",
  AccountBalance: "موجودی حساب",
  FreezeAmount: "مقدار پردازش",
  Frozen: "پردازش",
  MessageList: "لیست پیام",
  OrderSubmission: "ارسال سفارش",
  CreateTime: "زمان ایجاد کنید",
  OrderNumber: "شماره سفارش",
  msg_login_success: "ورود با موفقیت",
  msg_logout_confirm: "آیا مطمئن هستید که می خواهید از حساب کاربری خارج شوید؟",
  msg_workingtime: "زمان کار",
  msg_depositwithdrawal:
    "برای واریز و برداشت، لطفا با خدمات مشتریان ما تماس بگیرید",
  msg_clicktocontactus: "برای تماس با خدمات مشتری کلیک کنید",
  msg_accountnotexist: "حساب یا رمز عبور نامعتبر است",
  msg_invalidpassword: "رمز عبور نامعتبر است",
  msg_grab_order_period:
    "این عملیات فقط در زمان مشخص شده قابل انجام است [START]:00 - [END]:00",
  msg_grab_order_disabled:
    "این عملیات غیرفعال شد. لطفا با خدمات مشتری تماس بگیرید.",
  msg_account_disabled: "حساب غیرفعال شده است",
  msg_account_frozen: "عملکرد تراکنش حساب مسدود شده است",
  msg_account_gap_amount:
    "موجودی حساب شما کافی نیست، یک شکاف وجود دارد[REPLACEVALUE1]. لطفا با خدمات مشتری تماس بگیرید.",
  msg_order_processing_success: "پردازش سفارش تکمیل شد",
  msg_progress_note1: "همگام سازی آخرین اطلاعات...",
  msg_progress_note2: "بررسی اطلاعات محصول...",
  msg_progress_note3: "مطابقت سفارشات محصولات ...",
  msg_progress_note4: "پردازش بهترین محصول ...",
  msg_receive_withdrawal_transfer:
    "ظرف نیم ساعت برداشت خود را دریافت خواهید کرد",
  msg_insufficient_creditscore: "نمره اعتباری ناکافی",
  msg_invalid_password: "رمز عبور نامعتبر",
  msg_input_withdrawalpassword: "لطفا رمز برداشت را وارد کنید",
  msg_please_input_userid: "لطفا نام کاربری خود را وارد کنید",
  msg_please_input_pass: "لطفا رمز ورود را وارد کنید",
  msg_please_input_mobileno: "لطفا شماره موبایل خود را وارد کنید",
  msg_pass_min_length: "طول رمز عبور باید حداقل 8 باشد",
  msg_pass_format: "رمز عبور باید فقط شامل حروف و اعداد با طول 8 تا 12 باشد",
  msg_pass_confirm: "لطفا رمز ورود را دوباره وارد کنید",
  msg_withpass_min_length: "طول رمز برداشت باید حداقل 8 باشد",
  msg_withpass_format:
    "رمز برداشت باید فقط شامل حروف و اعداد با طول 8 تا 12 باشد",
  msg_please_input_invitecode: "لطفا کد دعوت نامه را وارد کنید",
  msg_balance_insufficient:
    "غير قادر على بدء التداول لأن الرصيد أقل من الحد الأدنى لقيمة التنشيط، يرجى الاتصال بخدمة العملاء للحصول على مزيد من المساعدة",
  msg_recharge: "الرجاء إدخال رقم الحساب البنكي",
  msg_bank_pass_notice:
    "عزيزي المستخدم، يرجى عدم إدخال كلمة المرور الخاصة بحسابك البنكي في هذا المربع. لن يطلب منك موظفونا تقديم كلمة مرور حسابك المصرفي.",
  msg_mandatory_bankname: "الرجاء إدخال اسم البنك",
  msg_mandatory_bankaccname: "الرجاء إدخال اسم الحساب البنكي",
  msg_mandatory_bankaccno: "الرجاء إدخال رقم الحساب البنكي",
  msg_bank_pass_notice2:
    "ملاحظات: إذا كان المستخدم بحاجة إلى إعادة تعيين عنوان المحفظة، فيرجى الاتصال بخدمة العملاء لدينا",
};
window.ir = ir;
var ru = {
  Home: "Главная",
  Boost: "способствовать росту",
  Rejected: "Отклоненный",
  Completed: "Завершенный",
  Pending: "В ожидании",
  Remark: "Примечание",
  WithdrawalAmount: "Сумма вывода",
  WithdrawalHistory: "История вывода средств",
  TopWithdrawal: "верхний вывод",
  Recharge: "Пополнить",
  Withdrawal: "Вывод средств",
  Transaction: "Транзакция",
  Invite: "Пригласите",
  HOME: "дом",
  Starting: "Начало",
  Record: "Запись",
  Profile: "Профиль",
  ProductName: "Название продукта",
  ProductNumber: "Номер продукта",
  OrderHistory: "История заказов",
  All: "все",
  BindWallet: "Переплетенный кошелек",
  Logout: "Выход из системы",
  MandatoryWalletAddress: "*Адрес кошелька",
  Exchange: "Имя биржи",
  Commission: "вознаграждение",
  CurrentBalance: "Текущий баланс",
  ContactUs: "Свяжитесь с нами",
  LoginNameMobileNo: "Имя пользователя / номер мобильного телефона",
  Password: "Пароль",
  ConfirmPassword: "Подтвердите пароль",
  WithdrawalPassword: "Пароль для снятия денег",
  ChangePassword: "Изменить пароль",
  ForgotPass: "Забыли пароль?",
  SignIn: "Войти",
  Register: "Регистрация",
  Username: "Имя пользователя",
  Login: "авторизоваться",
  RegisterAgreement: "Регистрационное соглашение",
  MobileNo: "Мобильный Нет",
  InvitationCode: "Код приглашения",
  CreditScore: "Кредитный балл",
  BackToLogin: "Назад К входу",
  MembershipLevel: "Уровень членства",
  TodaysCommission: "Сегодняшняя комиссия",
  TotalAmount: "Общая сумма",
  LatestEvent: "Последнее событие",
  AboutUs: "О нас",
  TNC: "T&C",
  TeamReport: "Отчет команды",
  BindWallet: "Переплетенный кошелек",
  Certificate: "Сертификат",
  OnlineUsers: "Онлайн-пользователи",
  FromLastMonth: "Из прошлого месяца",
  OrderCompletion: "Завершение заказа",
  OptimizeDemand: "Оптимизация спроса",
  OrderQuantity: "Заказ Количество",
  Confirm: "Подтверждать",
  Cancel: "Отмена",
  Submit: "подавать",
  Profit: "Прибыль",
  Value: "Ценить",
  Completed: "Terminado",
  StartNow: "Начните прямо сейчас",
  AccountBalance: "Остаток на счете",
  FreezeAmount: "Сумма обработки",
  Frozen: "Обработка",
  MessageList: "Список сообщений",
  OrderSubmission: "Подача заказа",
  CreateTime: "Создать время",
  OrderNumber: "Номер заказа",
  OrderDetails: "Детали заказа",
  MyReferralCode: "Мой реферальный код",
  CopyReferralCode: "Скопируйте реферальный код",
  msg_login_success: "Успешное вхождение в систему",
  msg_logout_confirm: "Вы уверены, что хотите выйти из учетной записи?",
  msg_workingtime: "Время работы",
  msg_depositwithdrawal:
    "По вопросам пополнения и снятия средств, пожалуйста, обращайтесь в нашу службу поддержки клиентов",
  msg_clicktocontactus:
    "Нажмите, чтобы связаться со службой поддержки клиентов",
  msg_accountnotexist: "Неверная учетная запись или пароль",
  msg_invalidpassword: "Неверный пароль",
  msg_grab_order_period:
    "Эта операция может быть выполнена только в течение указанного времени [START]:00 - [END]:00",
  msg_withdrawalamount_range:
    "Лимит на снятие средств составляет [MINAMT] - [MAXAMT]",
  msg_grab_order_disabled:
    "Эта операция отключена. Обратитесь в службу поддержки клиентов.",
  msg_account_disabled: "Учетная запись была отключена",
  msg_account_frozen: "Функция операций со счетами была заморожена",
  msg_account_gap_amount:
    "Баланс вашего счета недостаточен, существует разрыв в [REPLACEVALUE1]. Обратитесь в службу поддержки клиентов.",
  msg_order_processing_success: "Обработка заказа завершена",
  msg_progress_title:
    "на полную мощность, Estimate нужно<span style='color: red'> &nbsp;3s&nbsp;</span>Пожалуйста, не покидайте интерфейс!",
  msg_progress_note1: "Синхронизация последней информации...",
  msg_progress_note2: "Проверка информации о продукте...",
  msg_progress_note3: "Сопоставление заказов на продукцию...",
  msg_progress_note4: "Обработка лучшего продукта...",
  msg_receive_withdrawal_transfer: "Вы получите деньги в течение получаса",
  msg_insufficient_creditbalance: "Недостаточный кредит",
  msg_insufficient_creditscore: "Недостаточная кредитная история",
  msg_invalid_password: "Неверный пароль",
  msg_input_withdrawalpassword: "Пожалуйста, введите пароль для вывода средств",
  msg_please_input_userid: "Пожалуйста, введите ваше имя пользователя",
  msg_please_input_pass: "Пожалуйста, введите пароль для входа в систему",
  msg_please_input_mobileno:
    "Пожалуйста, введите номер вашего мобильного телефона",
  msg_pass_min_length: "Длина пароля должна быть не менее 6",
  msg_pass_format:
    "Пароль должен содержать только буквы и цифры длиной от 6 до 50",
  msg_pass_confirm: "Пожалуйста, введите пароль для входа еще раз",
  msg_withpass_min_length: "Длина пароля для снятия должна быть не менее 6",
  msg_withpass_format:
    "Пароль для вывода средств должен содержать только буквы и цифры длиной от 6 до 50",
  msg_please_input_invitecode: "Введите код приглашения",
  msg_balance_insufficient:
    "Невозможно начать торговлю из-за того, что баланс ниже минимального значения активации. Обратитесь в службу поддержки клиентов для получения дополнительной помощи.",
  msg_recharge:
    "Пожалуйста, свяжитесь со службой поддержки клиентов для получения дополнительной помощи",
  msg_bank_pass_notice:
    "Уважаемый пользователь, Пожалуйста, не вводите в это поле пароль своего банковского счета. Наши сотрудники не потребуют от вас предоставить пароль от банковского счета..",
  msg_mandatory_bankname: "Пожалуйста, введите название банка",
  msg_mandatory_bankaccname: "Пожалуйста, введите название банковского счета",
  msg_mandatory_bankaccno: "Пожалуйста, введите номер банковского счета",
  msg_bank_pass_notice2:
    "Примечания: Если пользователю необходимо сбросить адрес кошелька, обратитесь в нашу службу поддержки клиентов.",
  msg_username_exist: "Дублированное имя пользователя",
  msg_invalid_invitationcode: "неверный код приглашения",
  msg_inactive_invitationcode: "код приглашения неактивен",
  msg_invitation_notallowed: "у вашего реферера нет разрешения приглашать",
  msg_mandatory_ordercompletebeforerequest:
    "Завершите заказы, прежде чем подавать новый запрос",
};
window.ru = ru;
window.lang = (function () {
  function lang(defaultLang) {
    this.allDictionaries = {
      en: en,
      es: es,
      sa: sa,
      ir: ir,
      ru: ru,
      kr: kr,
    };
    this.dictionary = en;
    this.currentLang = "en";
    this.supportedLang = Object.keys(this.allDictionaries);
    this.singleLangCode = "en";
    this.setLang(defaultLang);
  }
  lang.prototype.setLang = function (newLanguage) {
    var self = this;
    if (newLanguage) {
      var preLangCode = self.currentLang;
      helperObj.setCookie("lang", newLanguage, "/");
      newLanguage = newLanguage.toLocaleLowerCase();
      if (this.supportedLang.indexOf(newLanguage) >= 0) {
        self.currentLang = newLanguage;
        self.singleLangCode = newLanguage.substring(
          self.currentLang.indexOf("-") + 1,
          newLanguage.length
        );
      } else {
        var errMsg = "Lang " + newLanguage + " is not supported.";
        self.throwError(errMsg);
      }
      if (newLanguage.indexOf("-") > 0) {
        newLanguage = newLanguage.substring(
          newLanguage.indexOf("-") + 1,
          newLanguage.length
        );
      }
      var bodyEle = document.getElementsByTagName("body")[0];
      bodyEle.classList.remove(preLangCode);
      bodyEle.classList.add(newLanguage);
    }
    self.dictionary = self.allDictionaries[newLanguage];
  };
  lang.prototype.getWord = function (word, replaceValue1) {
    if (replaceValue1 === void 0) {
      replaceValue1 = "";
    }
    var self = this;
    var selectedWord = null;
    if (word) {
      if (_ && _.get) {
        var translatedWord = _.get(self.dictionary, word);
        if (!translatedWord && self.currentLang != "en") {
          translatedWord = _.get(self.allDictionaries.en, word);
        }
        if (
          translatedWord &&
          typeof translatedWord === "string" &&
          translatedWord.includes("[REPLACEVALUE1]")
        ) {
          translatedWord = translatedWord.replace(
            "[REPLACEVALUE1]",
            replaceValue1
          );
        }
        return translatedWord ? translatedWord : word;
      }
      if (self.dictionary[word]) {
        selectedWord = self.dictionary[word];
      } else {
        var wordKeys = Object.getOwnPropertyNames(en);
        var foundWords = wordKeys.filter(function (indexWord) {
          return indexWord.toUpperCase() === word.toUpperCase();
        });
        if (foundWords && foundWords.length) {
          selectedWord = self.dictionary[foundWords[0]];
        }
        if (!selectedWord && self.currentLang != "en") {
          selectedWord = en[word];
        }
        if (!selectedWord) {
          selectedWord = word;
        }
        var errMsg =
          "Word " +
          word +
          " is not available in " +
          self.currentLang +
          ". Please update the lang dictionary.";
        self.throwError(errMsg);
      }
    }
    return selectedWord;
  };
  lang.prototype.translateWord = function () {
    var self = this;
    var $words = $("[data-lang]");

    for (var _b = 0, _c = $words.toArray(); _b < _c.length; _b++) {
      var word = _c[_b];
      var $word = $(word);
      var translatedWord = self.getWord(
        word.attributes.getNamedItem("data-lang").value
      );

      if (word.tagName === "INPUT") {
        $word.attr("placeholder", translatedWord);
      } else {
        $word.text(translatedWord);
      }
    }
  };
  lang.prototype.translateWordByElement = function ($element) {
    var self = this;
    var $words = $element.find("[data-lang]");
    for (var _b = 0, _c = $words.toArray(); _b < _c.length; _b++) {
      var word = _c[_b];
      var $word = $(word);
      var translatedWord = self.getWord(
        word.attributes.getNamedItem("data-lang").value
      );
      if (word.tagName === "INPUT") {
        $word.attr("placeholder", translatedWord);
      } else {
        $word.text(translatedWord);
      }
    }
  };
  lang.prototype.throwError = function () {
    var errMsg = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      errMsg[_i] = arguments[_i];
    }
    console.log("Lang.js validation error log", errMsg);
  };
  return lang;
})();
